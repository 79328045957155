const getPanelConfig = (values, typeSelected) => {
  const {
    panelNumber,
    panelParallel,
  } = values;

  let panelConfig = "";

  if (typeSelected === 0) {
    // All serial
    panelConfig = panelNumber + "s";
  } else if (typeSelected === 1) {
    // All parallel
    panelConfig = panelNumber + "p";
  } else {
    // Mixed
    if (panelNumber > 1) {
      panelConfig = panelNumber + "s";
    }

    if (panelParallel > 1) {
      panelConfig += panelParallel + "p";
    }
  }

  return panelConfig;
}

export default getPanelConfig;
