import React, {useEffect, useState} from 'react';
import {Row, Col, Form} from "react-bootstrap";

import {convertFtoC, options} from "../shared";

import "./PanelComplex.css";

const PanelComplex = ({
  initialValues,
  typeSelected,
  setValues,
}) => {
  const [minTemp, setMinTemp] = useState(initialValues.minTemperature);
  const [stcTemp, setStcTemp] = useState(initialValues.panelStc);
  const [temperatureScale, setTemperatureScale] = useState("c");
  const [temperatureScaleStc, setTemperatureScaleStc] = useState("c");

  const handleChange = (e, type, withNegative = false) => {
    const newValues = {...initialValues};
    const valueToPass = withNegative ? e.target.value : Number(e.target.value);
    newValues[type] = valueToPass;

    if (type === "minTemperature") {
      setMinTemp(valueToPass);
    } else if (type === "panelStc") {
      setStcTemp(valueToPass);
    }

    if (
      (type === "minTemperature" && temperatureScale === "f") ||
      (type === "panelStc" && temperatureScaleStc === "f")
    ) {
      newValues[type] = convertFtoC(valueToPass); // Always
    }
    setValues(newValues);
  };

  useEffect(() => {
    handleChange({target: {value: minTemp}}, "minTemperature", true);
    handleChange({target: {value: stcTemp}}, "panelStc", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temperatureScale, temperatureScaleStc]);

  return (
    <>
      <Row className="App-Panel-form">
        {
          typeSelected === 2 && (
            <Form.Group as={Col} controlId="panel-parallel">
              <Form.Label className="App-Data-total-watt">
                Number of parallel:
              </Form.Label>
              <Form.Select
                type="number"
                value={initialValues.panelParallel}
                onChange={(e) => handleChange(e, "panelParallel")}
              >
                {options}
              </Form.Select>
            </Form.Group>
          )
        }

        <Form.Group as={Col} controlId="panel-number">
          <Form.Label className="App-Data-total-watt">
            {
              typeSelected === 2
                ? "Panels in series:"
                : "Number of panels:"
            }
          </Form.Label>
          <Form.Select
            type="number"
            value={initialValues.panelNumber}
            onChange={(e) => handleChange(e, "panelNumber")}
          >
            {options}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="panel-watt">
          <Form.Label className="App-Data-total-watt">
            Watt per panel:
          </Form.Label>
          <Form.Control
            type="number"
            step="1"
            value={initialValues.panelWatt}
            onChange={(e) => handleChange(e, "panelWatt")}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="panel-openvoltage">
          <Form.Label className="App-Data-total-watt">
            Voltage (Voc):
          </Form.Label>
          <Form.Control
            type="number"
            step="1"
            value={initialValues.openVoltage}
            onChange={(e) => handleChange(e, "openVoltage")}
          />
        </Form.Group>
      </Row>
      <Row className="App-Panel-form">
        <Form.Group as={Col} controlId="panel-lowTemp">
          <Form.Label className="App-Data-total-watt">
            Lowest Temp (°{temperatureScale.toUpperCase()}):
          </Form.Label>
          <Form.Group className="App-Panel-temp-group">
            <Form.Control
              type="text"
              required
              value={minTemp}
              onChange={(e) => handleChange(e, "minTemperature", true)}
            />
            <Form.Select
              value={temperatureScale}
              onChange={(e) => {
                setTemperatureScale(e.target.value);
              }}
            >
              <option key="c" value="c">°C</option>
              <option key="f" value="f">°F</option>
            </Form.Select>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Col} controlId="panel-stc">
          <Form.Label className="App-Data-total-watt">
            Panel STC (°{temperatureScaleStc.toUpperCase()}):
          </Form.Label>
          <Form.Group className="App-Panel-temp-group">
            <Form.Control
              type="text"
              value={stcTemp}
              onChange={(e) => handleChange(e, "panelStc", true)}
            />
            <Form.Select
              value={temperatureScaleStc}
              onChange={(e) => {
                setTemperatureScaleStc(e.target.value);
              }}
            >
              <option key="c" value="c">°C</option>
              <option key="f" value="f">°F</option>
            </Form.Select>
          </Form.Group>
        </Form.Group>

        <Form.Group as={Col} controlId="panel-imp">
          <Form.Label className="App-Data-total-watt">
            Max power current Imp(A):
          </Form.Label>
          <Form.Control
            type="number"
            value={initialValues.panelImp}
            onChange={(e) => handleChange(e, "panelImp")}
          />
        </Form.Group>
      </Row>
      <Row className="App-Panel-form">
        <Form.Group as={Col} controlId="panel-coefVoc">
          <Form.Label className="App-Data-total-watt">
            Temperature Coefficient of Voc:
          </Form.Label>
          <Form.Control
            type="text"
            value={initialValues.coefVoc}
            onChange={(e) => handleChange(e, "coefVoc", true)}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="panel-coefPmax">
          <Form.Label className="App-Data-total-watt">
            Temperature Coefficient of MPP:
          </Form.Label>
          <Form.Control
            type="text"
            value={initialValues.coefPmax}
            onChange={(e) => handleChange(e, "coefPmax", true)}
          />
        </Form.Group>
      </Row>
    </>
  );
};

export default PanelComplex;
