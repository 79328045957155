import React from "react";
import {Col, Row} from "react-bootstrap";

import PanelImgP from "../../images/panel-p.png";
import PanelImgS from "../../images/panel-s.png";
import PanelImgSR from "../../images/panel-s-r.png";

const listPanel = [
  "Serial",
  "Parallel",
  "Mix"
];

const PanelImg = ({values, typeSelected}) => {
  return (
    <Row className="App-Panel-img">
      {
        values.panelParallel === 0 || values.panelNumber === 0
          ? null
          : (
            <Col>
              {
                typeSelected === 0 || typeSelected === 1
                  ? (
                    <>
                      <div className="wrap-cell  wrap-other">
                        {
                          Array(values.panelNumber)
                            .fill(1)
                            .map((a, i) => (
                              <div
                                key={i}
                                className={`img-cell ${listPanel[typeSelected]}`}
                                style={{width: `calc(100% / ${values.panelNumber})`}}
                              >
                                <img
                                  alt="panel"
                                  className={listPanel[typeSelected]}
                                  key={i}
                                  src={typeSelected === 1 ? PanelImgP : PanelImgS}
                                />
                              </div>
                            ))
                        }
                      </div>
                      <div className="panel-watt">
                        {`${values.panelWatt}W ${values.panelNumber > 1 ? `X${values.panelNumber}` : ""}`}
                      </div>
                    </>
                  )
                  : (
                    <>
                      {
                        Array(values.panelParallel)
                          .fill(1)
                          .map((acc, ii) => (
                            <div
                              key={ii}
                              className="main-wrap"
                            >
                              <div className="wrap-cell wrap-parallel">
                                {
                                  Array(values.panelNumber)
                                    .fill(1)
                                    .map((a, i) => (
                                      <div
                                        key={i}
                                        className="img-cell Serial"
                                        style={{width: `calc(100% / ${values.panelNumber})`}}
                                      >
                                        <img
                                          alt="panel"
                                          className={listPanel[typeSelected]}
                                          key={i}
                                          src={typeSelected === 1 ? PanelImgP : ii%2 === 0 ? PanelImgS : PanelImgSR}
                                        />
                                      </div>
                                    ))
                                }
                              </div>
                              <div className="panel-watt">
                                {`${values.panelWatt}W ${values.panelNumber > 1 ? `X${values.panelNumber}` : ""}`}
                              </div>
                            </div>
                          ))
                      }
                    </>
                  )
              }

            </Col>
          )
      }

    </Row>
  )
}

export default PanelImg;
