import React from "react";
import {Accordion, Container} from "react-bootstrap";

import logo from "./images/logo2.png";

const TopContainer = () => {
  return (
    <Container className="App-why">
      <img
        height={130}
        width={296}
        src={logo}
        className="App-why-logo"
        alt="Solar Charge Controller Calculator"
      />
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Find the right solar charge controller</Accordion.Header>
          <Accordion.Body className="why-body">
            Thanks to the Solar Charge Controller calculator, you will be able to size your Solar Charge Controller for
            your solar panel setup.
            <br/><br/><b>You can choose two modes:</b>
            <br/><br/>
            <b>- The Easy Mode:</b><br/>
            This is if you want a fast response without filling in all details of your panel's array.
            <br/>We use a simple formula: Nbr Panel * Watt per Panel / Battery Voltage. Then we add 25% for cold
            temperature safety.
            <br/><br/>
            <b>- The Power Mode:</b><br/>
            This uses more complex formulas based on the science article{" "}
            <a
              target="_blank"
              rel="noreferrer"
              alt="solar charge controller article"
              href="https://sci-hub.se/https://www.sciencedirect.com/science/article/abs/pii/S0038092X08002788"
            >
              "On the temperature dependence of photovoltaic module
              electrical performance"
            </a>
            <br/><br/>
            We strongly encourage the use of an MPPT and avoiding a PWM (cheaper but less efficient).
            <br/><br/>
            <div className="disclaimer">
              This tool is for informational purposes only and is provided "as is".
              No responsibility will be accepted by us for the use of this tool or for any outcomes that may arise by
              using the tool.
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  );
}

export default TopContainer;
