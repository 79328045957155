const explanations = {
  battery_voltage: {
    title: "Battery bank voltage",
    content: "Select the nominal voltage output of your battery bank"
  },
  panel: {
    title: "Panel Array Setup",
    content: (
      <div>
        <b>You can use two different versions.</b>
        <br/><br/>
        The <b>Power user</b> version is when you know all details of your solar panels.
        <br/>
        <br/>
        <u>The Power mode need:</u><br/>
        - Estimated Low Temperature in your area<br/>
        - Panel Open-Circuit Voltage<br/>
        - Panel STC value<br/>
        - Panel maximum power current (Imp)<br/>
        - Temperature Coefficient of Voc<br/>
        - Temperature Coefficient of Pmax.
        <br/><br/>
        All these information should be found behind your solar panels.
        <br/><br/>
        <u>The Easy mode need:</u><br/>
        Mainly the Voc (Panel Open-Circuit Voltage) and we will add 25% for cold temperatures.
        <br/><br/>
        <b>
        Both mode will ask you about your solar panel setup (serial and parallel or mix) and Watt per panel
        </b>
      </div>
    )
  },
  mppt_result: {
    title: "Charge controller results",
    content: (
      <div>
        <br/>
        <b>The min voltage</b> is what your charge controller should at least handle as minimum voltage.
        This is also the max voltage you can expect from your solar panel array at your estimated low temperature.
        <br/>
        <br/>
        <b>The min current rating</b> represents the maximum current your charge controller can output at your estimated low temperature.
      </div>
    )
  },
  daysup: {
    title: "Days of autonomy",
    content: "Enter the maximum number of days you wants to have as backup to power your system. In case of cloudy or rainy days, your system will mainly use your batteries as source of power and you want to be sure you size correctly the battery bank base on your consumption."
  }
};


export {
  explanations
};
