import React from "react";
import SwitchSelector from "react-switch-selector";
import {Container} from "react-bootstrap";

import {optionsToggle} from "../DataStep/shared";

import "./SwitchPowerUser.css";

const SwitchPowerUser = ({
   configType,
   setConfigType,
}) => {
  const onChange = (newValue) => {
    setConfigType(newValue);
  };

  return (
    <Container className="App-switch-poweruser">
      <h6>Switch Mode</h6>
      <SwitchSelector
        onChange={onChange}
        options={optionsToggle}
        forcedSelectedIndex={configType}
        backgroundColor={"#353b48"}
        fontColor={"#f5f6fa"}
      />
    </Container>
  );
};

export default SwitchPowerUser;
