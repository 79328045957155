import React from "react";

const countOptionsPanel = 15;

export const options = Array(countOptionsPanel)
  .fill(1)
  .map((a, i) => (
    <option key={i} value={i + 1}>{i + 1}</option>
  ));

export const convertFtoC = (temp) =>  (temp - 32) * 5 / 9;

export const listPanel = [
  "Serial",
  "Parallel",
  "Mix"
];

export const optionsToggle = [
  {
    label: <span>Easy Mode</span>,
    value: 0,
    selectedBackgroundColor: "#FFC108"
  },
  {
    label: <span>Power Mode</span>,
    value: 1,
    selectedBackgroundColor: "repeating-linear-gradient(45deg,rgba(0, 0, 0, 0.01),rgba(0, 0, 0, 0.01) 10px,rgba(0, 0, 0, 0.1) 10px,rgba(0, 0, 0, 0.1) 20px), #FFC108",
  },
];