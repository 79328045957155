// const baseFormula = (stc, lowTemp, coef, x) => ((stc - lowTemp) * (coef / -100) * x) + x;

export const calcMaxVoltage = (v, typeSelected) => {
  const {
    panelNumber,
    // panelWatt,
    panelParallel,
    openVoltage,
    batteryVoltage,
    minTemperature,
    panelStc,
    panelImp,
    coefVoc,
    // coefPmax,
  } = v;

  // const maxPanelNumber = typeSelected < 2 ? panelNumber : panelParallel * panelNumber;
  // const maxVoltLowTempByPanel = baseFormula(panelStc, minTemperature, coefVoc, openVoltage);

  // let maxVoltagePanelArray = maxVoltLowTempByPanel * panelNumber; // Default case 0 - Panels in series

  // if (typeSelected === 1) {
    // Only parallel panels
    // maxVoltagePanelArray = maxVoltLowTempByPanel; // No matter the number of parallel, the maxV is going to be the same as one
  // } else if (typeSelected === 2) {
    // Mixed parallel and series panel
    // maxVoltagePanelArray = maxVoltLowTempByPanel * panelNumber; // Here PanelNumber is the nbr of panel in series
  // }

  // const maxWattByPanel = baseFormula(panelStc, minTemperature, coefPmax, panelWatt);
  // const maxWattPanelArray = maxWattByPanel * maxPanelNumber;

  const batteryRealVoltInput = {
    12: 14.4,
    24: 28.8,
    48: 57.6
  };
  // const finalAmpByPanel = maxWattByPanel / batteryRealVoltInput[batteryVoltage];
  // const maxAmpPushedInBattery = finalAmpByPanel * maxPanelNumber;

  // console.log("\n====================================================");
  // console.log("maxVoltLowTempByPanel", maxVoltLowTempByPanel);


  // console.log("-------    ------");
  // console.log("maxWattByPanel", maxWattByPanel);
  // console.log("Max amp per panel", maxWattByPanel / maxVoltLowTempByPanel);

  // console.log("-------    ------");
  // console.log("finalAmpByPanel", finalAmpByPanel);
  // console.log("maxVoltLowTempByPanel", maxVoltLowTempByPanel);
  // console.log("*******    *******");
  // console.log("FINAL MAX Voltage for MPPT", maxVoltagePanelArray);
  // console.log("FINAL MAX AMP for MPPT", maxAmpPushedInBattery);
  const maxVoltFormula = ((panelStc - minTemperature) * (coefVoc / -100) * openVoltage ) + openVoltage;
  // const maxApmExplorsit = maxVoltExplorist * panelNumber * panelImp / batteryRealVoltInput[batteryVoltage];
  // console.log("FINAL MAX AMP EXPLORIST", maxVoltExplorist * panelNumber * panelImp / batteryRealVoltInput[batteryVoltage]);

  // console.log("panelParallel", panelParallel, "maxApmExplorsit", maxApmExplorsit, "typeSelected", typeSelected);
  // console.log("PARALLEL ? maxPanelNumber",maxPanelNumber);

  const panelCountVolt = typeSelected === 0
    ? panelNumber : typeSelected === 1
      ? 1 : panelNumber;
  const panelCountAmp = typeSelected === 0
    ? 1 : typeSelected === 1
      ? panelNumber : panelParallel;

  const maxVoltMPPT = maxVoltFormula * panelCountVolt;
  const minAmpParallel = (maxVoltMPPT  *  (panelImp * panelCountAmp) ) / batteryRealVoltInput[batteryVoltage];
  // console.log("AMP", minAmpParallel, "maxVoltMPPT", minAmpParallel);
  // console.log("Max Amps Going Into the Charge Controller", panelImp * panelCountAmp)
  // console.log("maxVoltFormula", maxVoltFormula);
  return {
    minMPPTAmp: minAmpParallel,
    minMPPTVolt: maxVoltMPPT,
    maxVoltagePanel: maxVoltFormula,
    maxAmpIntoMPPT: panelImp * panelCountAmp
  }
};

// Actif
// (number of panel * max Watt per panel) / nominal volt battery
// (5 * 100) / 14.4

// OLD
// (number of panel * regular Watt per panel) / nominal volt battery
// (5 * 132.9) / 14.4

// NEW
// ( maxVolt * Imp) / nominal volt battery
// (139.8864*5.29) / 14.4
//


// max volt
// ((STC - lowTemp) * (coef Voc / -100) * voc ) + voc

// Amp parallel only
// maxAmpEnteringMMPT = Imp * nbr PV parallel
// (maxVoltExplorist  *  (Imp * nbr PV parallel) ) / batteryVOltage
// Amp Serie
// (maxVoltExplorist * (Imp * panelParallel) ) / batteryVOltage

// fieldname30*fieldname24
// imp * panelParallel ?

export const roundDecimal = (x) => Number(Number.parseFloat(x).toFixed(1));

export const getEasyMaxVoltage = (panelNumber, openVoltage, typeSelected) => {
  let virtualNbrPanel = typeSelected === 1
    ? 1 // Parallel only
    : panelNumber;

  return virtualNbrPanel * openVoltage * 1.25;
}