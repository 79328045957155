import React from 'react';
import {Row, Col, Form} from "react-bootstrap";

import {options} from "../shared";

const Panel = ({
  initialValues,
  typeSelected,
  setValues
}) => {
  const handleChange = (e, type) => {
    const newValues = {...initialValues};
    newValues[type] = Number(e.target.value);
    setValues(newValues);
  };

  return (
    <>
      <Row className="App-Panel-form">
        {
          typeSelected === 2 && (
            <Form.Group as={Col} controlId="panel-parallel">
              <Form.Label className="App-Data-total-watt">
                Number of parallel:
              </Form.Label>
              <Form.Select
                type="number"
                value={initialValues.panelParallel}
                onChange={(e) => handleChange(e, "panelParallel")}
              >
                {options}
              </Form.Select>
            </Form.Group>
          )
        }


        <Form.Group as={Col} controlId="panel-number">
          <Form.Label className="App-Data-total-watt">
            {
              typeSelected === 2
                ? "Panels in series:"
                : "Number of panels:"
            }
          </Form.Label>
          <Form.Select
            type="number"
            value={initialValues.panelNumber}
            onChange={(e) => handleChange(e, "panelNumber")}
          >
            {options}
          </Form.Select>
        </Form.Group>

        <Form.Group as={Col} controlId="panel-watt">
          <Form.Label className="App-Data-total-watt">
            Watt per panel:
          </Form.Label>
          <Form.Control
            type="number"
            step="1"
            value={initialValues.panelWatt}
            onChange={(e) => handleChange(e, "panelWatt")}
          />
        </Form.Group>

        <Form.Group as={Col} controlId="panel-openvoltage">
          <Form.Label className="App-Data-total-watt">
            Voltage (Voc):
          </Form.Label>
          <Form.Control
            type="number"
            step="1"
            value={initialValues.openVoltage}
            onChange={(e) => handleChange(e, "openVoltage")}
          />
        </Form.Group>

      </Row>
    </>
  );
};

export default Panel;

