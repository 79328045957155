import React from "react";
import {Accordion, Col, Container, Form, ListGroup, Row} from "react-bootstrap";

import ModalInfo from "../ModalInfo";
import Panel from "./Panel";
import PanelComplex from "./PanelComplex";
import PanelImg from "./PanelImg";

import {explanations} from "../global";
import getPanelConfig from "../tools/getPanelConfig";

import {
  listPanel
} from "./shared";

import "./DataStep.css";
import "./Panel.css";

const DataStep = ({
  initialValues,
  setValues,
  typeSelected,
  setTypeSelected,
  configType
}) => {
  const changePanelType = (typeId) => {
    setTypeSelected(typeId);
  };

  const RowPanelComponent = configType === 0 ? Panel : PanelComplex;
  const panelConfig = getPanelConfig(initialValues, typeSelected);

  return (
    <Container className="App-Data-container">
      <Accordion className="App-step-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header className={configType === 1 ? "power" : ""}>
            Panels Configuration - <span>{`${panelConfig}`}</span>
          </Accordion.Header>
          <Accordion.Body className="App-step-body">
            <div
              className="form-horizontal App-Data-form"
            >
              <Row>
                <Form.Group className="App-Data-version" as={Col}>
                  <Form.Label style={{width: "auto"}}>Which version do you want to use?</Form.Label>
                  <ModalInfo
                    title={explanations.panel.title}
                    content={explanations.panel.content}
                  />
                </Form.Group>
              </Row>

              <div className="App-Panel">
                <Row>
                  <ListGroup horizontal>
                    {
                      listPanel.map((panel, typeId) => (
                        <ListGroup.Item
                          active={typeSelected === typeId}
                          key={typeId}
                          onClick={() => changePanelType(typeId)}
                        >
                          {panel}
                        </ListGroup.Item>
                      ))
                    }
                  </ListGroup>
                </Row>

                <RowPanelComponent
                  initialValues={initialValues}
                  typeSelected={typeSelected}
                  setValues={setValues}
                />
              </div>

              <PanelImg
                typeSelected={typeSelected}
                values={initialValues}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
};

export default DataStep;
