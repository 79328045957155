import React, {Fragment} from "react";
import "./Disclaimer.css";

import {Accordion, Card, Container, useAccordionButton} from "react-bootstrap";
import {TiWarningOutline} from "react-icons/all";

const CustomToggle = ({ children, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {});

  return (
    <div className="App-disclaimer-other" onClick={decoratedOnClick}>
      {children}
    </div>
  );
};

const amazonFTC = "SolarPowerToolbox.com is a participant in the Amazon Services LLC Associates Program,\n" +
  "          an affiliate advertising program designed to provide a means for sites to earn advertising fees by advertising and linking to Amazon.com\n" +
  "          We make a commission for sales made through affiliate links posted on this website.";

const listSites = [
  "https://www.solarpowertoolbox.com",
  "https://www.solarpaneldiagram.com/",
  "https://www.solarpowersizingcalculator.com",
  "https://www.solarwiresizecalculator.com",
  "https://www.solarinvertercalculator.com/",
  "https://www.solarbatterybankcalculator.com"
];

const Disclaimer = ({withAmazon = true}) => (
  <div className="App-disclaimer">
      <Container>
        <div className="App-disclaimer-content">
          <Accordion className="App-disclaimer-accordion">
            <CustomToggle eventKey="0">Click to see other solar calculators</CustomToggle>

              <Accordion.Collapse eventKey="0">
                <Card>
                <Card.Body>
                  Here is a list of other solar calculators you can use.
                  {
                    listSites.map((site, i) => (
                      <Fragment key={i}>
                        <br/>
                        <a href={site}>
                          {site.split("https://")[1]}
                        </a>
                      </Fragment>
                    ))
                  }
                </Card.Body>
                </Card>
              </Accordion.Collapse>
          </Accordion>

          <div className="App-disclaimer-separator"/>
          Copyright  Solar Power Toolbox© 2021
          <br />
          <span className="disclaimer-toolbbox-info">
            {window.location.host} is part of SolarPowerToolbox.com,<br/>a collection of useful solar calculators and tools.
          </span>
          <br />
          {withAmazon && amazonFTC}
          <Accordion className="App-disclaimer-accordion">
            <CustomToggle eventKey="0">
              <TiWarningOutline /> Click to see our Special mentions
            </CustomToggle>
            <Accordion.Collapse eventKey="0">
              <Card>
                <Card.Body>
                  All our tools are for informational purposes only.
                  <br />
                  This tool is provided "as is" and no responsibility will be accepted
                  by us for the use of this tool or for any outcomes that may arise by using the tool.
                </Card.Body>
              </Card>
            </Accordion.Collapse>
          </Accordion>
        </div>
      </Container>
  </div>
);

export default Disclaimer;
