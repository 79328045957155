import React, {useState} from "react";
import { Helmet } from 'react-helmet';

import "./App.css";

import Disclaimer from "./Disclaimer";
import BatteryVolt from "./BatteryVolt";
import MpptStep from "./MpptStep";
import DataStep from "./DataStep";
import TopContainer from "./TopContainer";
import SwitchPowerUser from "./SwitchPowerUser";

export const initialValuesState = {
  panelNumber: 1,
  panelWatt: 115,
  panelParallel: 1,
  openVoltage: 23.32,
  batteryVoltage: 12,
  minTemperature: -40,
  panelStc: 25,
  panelImp: 6.04,
  coefVoc: -0.35,
  coefPmax: -0.44,
};

/*
listPanel = [
  "Serial",
  "Parallel",
  "Mix"
]
 */
const descMeta = "Calculate your solar charge controller size with this calculator. Find out which MPPT will fit your need with your solar power system";

const App = () => {
  const [initialValues, setInitialValues] = useState(initialValuesState);
  const [typeSelected, setTypeSelected] = useState(0); // see listPanel const
  const [configType, setConfigType] = useState(0); // 0: beginner - 1: Power user

  return (
    <div className="App">
      <Helmet>
        <title>Solar Charge Controller Calculator - MPPT Calculator</title>
        <meta name="description" content={descMeta} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@solarpowertoolbox" />
        <meta name="twitter:creator" content="@solarpowertoolbox" />
        <meta name="twitter:title" content="Solar Charge Controller Calculator" />
        <meta name="twitter:description" content={descMeta} />
        {/*<meta name="twitter:image" content="url_to_image"/>        */}
        <meta property="og:title" content="Solar Charge Controller Calculator" />
        <meta property="og:description" content={descMeta} />
        {/*<meta property="og:image" content="url_to_image”/>*/}
        <meta property="og:url" content="solarchargecontrollercalculator.com" />
        <meta property="og:site_name" content="Solar Charge Controller Calculator" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
      </Helmet>

      <TopContainer/>

      <SwitchPowerUser
        configType={configType}
        setConfigType={setConfigType}
      />

      <BatteryVolt
        initialValues={initialValues}
        setValues={setInitialValues}
      />

      <DataStep
        configType={configType}
        initialValues={initialValues}
        typeSelected={typeSelected}
        setValues={setInitialValues}
        setTypeSelected={setTypeSelected}
      />

      <MpptStep
        configType={configType}
        values={initialValues}
        typeSelected={typeSelected}
      />

      <Disclaimer withAmazon={false} />
    </div>
  );
}

export default App;
