import React, {useEffect, useState} from "react";
import {Accordion, Button, ButtonGroup, Col, Container, Form, Row} from "react-bootstrap";

import ModalInfo from "../ModalInfo";
import {explanations} from "../global";

import BatterySrc from "../images/battery.png";

import "./BatteryVolt.css";

const voltageOptions = [12,24,48];

const BatteryVolt = ({ setValues = () => {}, initialValues}) => {
  const [activeAnim, setActiveAnim] = useState(false);
  useEffect(() => {
    if (activeAnim) {
      setTimeout(() => {
        setActiveAnim(false);
      }, 400);
    }
  }, [activeAnim]);
  const onChangeVoltage = (v) => {
    const newValues = {
      ...initialValues,
      batteryVoltage: Number(v),
    };

    setActiveAnim(false);
    setActiveAnim(true);
    setValues(newValues);
  };

  return (
    <Container className="App-BatteryVolt-container">
      <Accordion className="App-step-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            Battery Voltage - <span>{initialValues.batteryVoltage}V</span>
          </Accordion.Header>
          <Accordion.Body className="App-step-body">
            <div className="form-horizontal">
              <Row className="g-1">
                <Form.Group as={Col} controlId="voltage">

                  <div className="App-BatteryVolt-img">
                    <img src={BatterySrc} alt="Battery Voltage"/>
                    <div className="App-BatteryVolt-img-value">
                      <p className={activeAnim ? "active" : "noanim"}>
                        {initialValues.batteryVoltage}
                      </p>
                      <p className={activeAnim ? "active" : "noanim"}>VOLT</p>
                    </div>
                  </div>

                  <div className="App-BatteryVolt">
                    <div className="App-BatteryVolt-average">
                      <ModalInfo
                        title={explanations.battery_voltage.title}
                        content={explanations.battery_voltage.content}
                      />
                      <ButtonGroup>
                        {
                          voltageOptions.map((v) => (
                            <Button
                              key={v}
                              className={initialValues.batteryVoltage === v ? "active" : ""}
                              onClick={() => onChangeVoltage(v)}
                            >
                              {v} Volt
                            </Button>
                          ))
                        }
                      </ButtonGroup>
                    </div>
                  </div>
                </Form.Group>
              </Row>

            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Container>
  )
};

export default BatteryVolt;
