import React from "react";
import {Container, Col} from "react-bootstrap";

import "./MpptStep.css";

import {
  getEasyMaxVoltage,
  roundDecimal,
  calcMaxVoltage
} from "../tools/calculator";
import getPanelConfig from "../tools/getPanelConfig";

import MpptSrc from "../images/mppt-icon.png";
import {explanations} from "../global";
import ModalInfo from "../ModalInfo";

const MpptStep = ({configType, values, typeSelected}) => {
  const nbrPanel = values.panelNumber * (typeSelected > 1 ? values.panelParallel : 1);
  let minMPPTVolt = getEasyMaxVoltage(values.panelNumber, values.openVoltage, typeSelected)
  let minMPPTAmp = (nbrPanel * values.panelWatt / values.batteryVoltage) * 1.25;
  let maxVoltagePanel = values.openVoltage * 1.25;

  if (configType === 1) {
    const powerValues = calcMaxVoltage(values, typeSelected);
    minMPPTAmp = powerValues.minMPPTAmp;
    minMPPTVolt = powerValues.minMPPTVolt;
    maxVoltagePanel = powerValues.maxVoltagePanel;
  }

  const panelConfig = getPanelConfig(values, typeSelected);

  return (
    <Container className="App-Mppt-container">
      <div className={`App-Mppt-container-wrapper${configType === 1 ? " power" : ""}`}>
        <h5>SOLAR CHARGE CONTROLLER SPECS</h5>
        <div className="App-Mppt-container-row">
          <Col className="App-Mppt-col">
            <img alt="Solar Charge Controller Calculator" src={MpptSrc} />
          </Col>
          <Col className="App-Mppt-col">
            <div className="App-Mppt-col-spec-detail panel">
              <label>Panels:</label>
              <br />
              <span>Number of panels <b>{` ${nbrPanel} panel${nbrPanel > 1 ? "s" :""} (${panelConfig})`}</b></span>
              <br/>
              <span>{`Max. Voltage per panel at low temperature `}<b>{roundDecimal(maxVoltagePanel)}V</b></span>
            </div>
            <div className="App-Mppt-col-spec-detail ccr">
              <label>
                Charge Controller requirements:
                <ModalInfo
                  title={explanations.mppt_result.title}
                  content={explanations.mppt_result.content}
                />
              </label>
              <br/>
              <span>{`Min. Voltage `}<b>{roundDecimal(minMPPTVolt)}V</b></span>
              <br />
              <span>{`Min. Current Rating	`}<b>{roundDecimal(minMPPTAmp)}A</b></span>
            </div>
          </Col>
        </div>
      </div>
    </Container>
  )
}

export default MpptStep;
